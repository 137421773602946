var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

site.direction = site.direction || {};

(function ($) {
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $swatchList = $('.js-swatch-list', $(this));

    if ($swatchList.length < 1) {
      return null;
    }
    Drupal.behaviors.swatchListV1.selectSku($swatchList, skuBaseId);
  });

  $(document).on('product.init', '.js-product', function (e) {
    var $swatchList = $('.js-swatch-list', $(this));

    if ($swatchList.length < 1) {
      return null;
    }
    Drupal.behaviors.swatchListV1.init($swatchList);
    var text = $(this).find('.js-shade-updating-message-count').text().split(':')[0];
    var shadeCount = $(this).find($('.js-sku-categories-content').find('.js-swatch-list-item')).length;

    text += ':' + shadeCount;
    if ($(this).parent().hasClass('js-quickshop__container')) {
      $swatchList.attr('aria-labelledby', 'js-shade-updating-message-count--' + shadeCount);
      $swatchList.parent().find('.js-shade-updating-message-count').attr('id', 'js-shade-updating-message-count--' + shadeCount);
    }
    $(this).find('.js-shade-updating-message-count').empty().append(text);
  });

  $('.js-swatch-list-item-link').on('keydown', function (e) {
    if (site.getKeycode(e) === 13) {
      $(this).trigger('click');
    }
  });

  $(document).on('click', '.js-swatch-list-item-link', function (e) {
    e.preventDefault();
    var $swatchLink = $(this);
    var selectedSkuBaseId = $swatchLink.attr('data-sku-base-id');
    var $productEl = prodcat.ui.getProductEl($swatchLink);

    $productEl.attr('data-sku-base-id', selectedSkuBaseId).trigger('product.skuSelect', selectedSkuBaseId);
    if ($swatchLink.hasClass('selected')) {
      $swatchLink.attr({
        'aria-selected': 'true',
        'aria-describedby': 'js-shade-updating-message'
      });
    }

    // If mobile, scroll the page so smoosh image is in view.
    if (Unison.fetch.now().name === 'small') {
      var $galleryTop;

      if ($('.js-product-gallery').length) { // On SPP
        // the above check assumes that all SPPs have .shaded_details which is
        // not true for bundle spps.
        if ($('.shaded_details').length == 0) {
          return;
        }
        $galleryTop = $('.shaded_details').offset().top - 50;
      }
      if ($('.mpp').length) { // On MPP quickshop
        $galleryTop = $('.js-quickshop-smoosh-wrapper').offset().top - 50;
      }
      if (e.which !== undefined) {
        $('html, body').animate({ scrollTop: $galleryTop }, 500);
      }
    }

    // Enabling deeplinking feature for Full Details link - CX-7243
    var $shadeContainer = $swatchLink.closest('.shaded_details');
    var $fullDetails = $shadeContainer.find('.js-full-details');
    var fullDetailsLink = $fullDetails.attr('href');

    if (fullDetailsLink) {
      var $selectSkuId = $shadeContainer.find('.js-sku-menu option[data-sku_base_id="' + selectedSkuBaseId + '"]');
      var selectShadeName = $selectSkuId.text();

      // Removing shade numbers (skin foundation shades)
      selectShadeName = selectShadeName.replace(/[0-9.()\ ]+$/g, '');
      var shadeName = selectShadeName.split(' ').join('_');
      var fullDetailsSplitLink = fullDetailsLink.split('#');
      var fullDetailsNewLink = fullDetailsSplitLink[0] + '#/shade/' + shadeName;
      var $productCtaBtn = $('.js-product-cta');

      $fullDetails.attr('href', fullDetailsNewLink);
      if ($productCtaBtn.length > 0) {
        $productCtaBtn.data('sku-base-id', selectedSkuBaseId);
      }
    }
  });

  $(document).on('mouseout', '.js-swatch-list-item-link', function () {
    var $swatchList = $(this).closest('.js-swatch-list');
    var skuBaseId = $swatchList.data('sku-base-id');
    var $productEl = prodcat.ui.getProductEl($swatchList);

    $productEl.removeClass('swatch-list-hover');
    $productEl.trigger('product.skuDisplay', skuBaseId);
  });

  $(document).on('mouseover focus', '.js-swatch-list-item-link', function () {
    var $swatchLink = $(this);
    var skuBaseId = $(this).data('sku-base-id');
    var $productEl = prodcat.ui.getProductEl($swatchLink);

    $productEl.addClass('swatch-list-hover');
    $productEl.trigger('product.skuDisplay', skuBaseId);
    if ($swatchLink.hasClass('selected')) {
      $swatchLink.attr('aria-selected', 'true');
    } else {
      $swatchLink.attr('aria-selected', 'false');
      $swatchLink.removeAttr('aria-describedby');
    }
  });

  Drupal.behaviors.swatchListV1 = {
    attach: function (context) {
      var self = this;

      self.init($('.js-swatch-list', context));
    },
    init: function ($swatchLists) {
      var self = this;

      $swatchLists.each(function () {
        var $swatchList = $(this);
        var $swatches = $('.js-swatch-list-item-link', $swatchList);
        var prodId = $swatchList.data('product-id');

        // Multi-Sku Palette
        // set bgcolors of swatches to 1st Shade from each SKU
        if (prodcat.data.isPaletteMultiSku(prodId)) {
          var prodData = prodcat.data.getProduct(prodId);

          _.map(prodData.skus, function (sku, idx) {
            sku.HEX_VALUE_STRING = sku.HEX_VALUE_STRING.replace(/\,\s*/, '\,');
            var shades = sku.HEX_VALUE_STRING.split(',');
            var swatchHex = shades[0];

            $($swatches[idx]).css({ 'backgroundColor': swatchHex });
          });
        }
        // Multi-Product Palette
        // set bgcolors of swatches to 1st Shade from 1st SKU of each Product
        if (prodcat.data.isPaletteMultiProduct(prodId)) {
          var tempProd = $.extend(true, {}, prodcat.data.getProduct(prodId));
          var prods = prodcat.data.getPaletteMultiProducts();

          tempProd.skus = [];
          _.map(prods, function (prod, idx) {
            var sku = $.extend(true, {}, prod['skus'][0]);

            sku['HEX_VALUE_STRING'] = sku['HEX_VALUE_STRING'].split(/\,\s*/)[0];
            tempProd.skus[idx] = sku;
          });
          var html = site.template.get({
            name: 'swatch_list_v1',
            data: tempProd
          });
          var $parent = $swatchList.parent();

          $swatchList.replaceWith(html);
          $swatchList = $('.js-swatch-list', $parent);
        }

        // Select first sku
        var $contextProductEl = prodcat.ui.getContextProductEl($swatchList);

        if (!$contextProductEl.data('default-shade-unselected')) {
          self.selectFirstSku($swatchList);
        }

        // add gray border to light shades (lighter than  R: 233 G: 223 B: 245 or hex #E9DFF5)
        $swatches.each(function () {
          var $swatchLink = $(this);
          var rgbStr = $swatchLink.css('backgroundColor');
          var matches = rgbStr.match(/(\d*)\,\s(\d*)\,\s(\d*)/);

          // IE8 returns a hex value; other browsers return rgb. We're not drawing outlines in IE8 anyway, so return.
          if (!matches) {
            return null;
          }
          var red = parseInt(matches[1]);
          var green = parseInt(matches[2]);
          var blue = parseInt(matches[3]);

          if (red > 233 && green > 223 && blue > 245) {
            $swatchLink.addClass('light-shade');
          }
        });
        if ($contextProductEl.data('carousel-swatch-list')) {
          self.initializeSlick($swatchList);
        }
        if ($contextProductEl.data('swatch-list-single-line')) {
          $swatchList.addClass('single-line');
        }

        $swatchList.on('resetSwatchList', function (e) {
          self.resetSwatchList($swatchList);
        });
        $swatchList.on('selectSwatch', function (e, skuBaseId) {
          self.selectSku($swatchList, skuBaseId);
        });
      });
    },
    initializeSlick: function ($swatchList) {
      var $slider = $swatchList;
      var unslick_breakpoint = 640;
      var settings = {
        rtl: site.direction.isRTL,
        infinite: false,
        autoplay: false,
        slidesToShow: 9,
        slidesToScroll: 8,
        prevArrow: null,
        nextArrow: null,
        slide: '.js-swatch-list-item'
      };

      function _slick_slider() {
        if ($(window).width() > unslick_breakpoint) {
          if ($slider.hasClass('slick-initialized')) {
            $slider.unslick();
          }

          return;
        }
        if (!$slider.hasClass('slick-initialized')) {
          return $slider.slick(settings);
        }
      }
      $(window).on('resize', _.debounce(_slick_slider, 100));
      _slick_slider();
    },
    selectFirstSku: function ($swatchList) {
      var $links = $('.js-swatch-list-item-link', $swatchList);
      var firstSkuBaseId = '';
      var prodId = $links.data('product-id');

      // return if any already selected ie. Shade Router
      if ($links.hasClass('selected')) {
        return null;
      }
      var $swatchLi = $('.js-swatch-list-item');

      if ($swatchLi.hasClass('js-default-sku-select_' + prodId)) {
        firstSkuBaseId = $('.js-default-sku-select_' + prodId).data('sku-base-id');
      } else {
        firstSkuBaseId = $links.first().data('sku-base-id');
      }
      $(".js-swatch-list-item-link[data-sku-base-id='" + firstSkuBaseId + "']", $swatchList).addClass('selected');
    },
    selectSku: function ($swatchList, skuBaseId) {
      $swatchList.data('sku-base-id', skuBaseId);
      var $links = $('.js-swatch-list-item-link', $swatchList);

      $links.removeClass('selected');
      $(".js-swatch-list-item-link[data-sku-base-id='" + skuBaseId + "']", $swatchList).addClass('selected');
    },
    resetSwatchList: function ($swatchList) {
      var $links = $('.js-swatch-list-item-link', $swatchList);

      $links.removeClass('selected');
    }
  };
})(jQuery);
